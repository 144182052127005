const emailArray = [
	'lakram@hotmail.fr',
	'alexandre.bonilla@yahoo.fr',
	'assalbrigitte92@gmail.com',
	'migan.unsa@yahoo.fr',
	'unsa.proprete.nettoyage@gmail.com',
	'unsa.nettoyage@unsa.org',
	'hfati@hotmail.fr',
	'franck.martinez3@wanadoo.fr',
	'freddy.hardet@orange.fr',
	'sidibekalilou@yahoo.fr',
	'unsa.hcr@unsa.org',
	'khadijachlait@hotmail.com',
	'lagomuche@orange.fr',
	'mouloud.nadia@neuf.fr',
	'unsa.adecco@gmail.com',
	'unsa.manpower@gmail.com',
	'fourseb13@gmail.com',
	'pelmarthierry1@gmail.com',
	'unsacompass@gmail.com',
	'lecomtejeanmichel1970@gmail.com',
	'bertrand.audoual@gmail.com',
	'frpoignant@orange.fr',
	'kristoy@msn.com',
	'wiartfreddy62@gmail.com',
	'catherineacho@live.fr',
	'cse-lagoa@maisons.dumonde.com',
	'fatiha.mokhtari@yahoo.fr',
	'ade.nacknouck@gmail.com',
	'vanessaarveyres@gmail.com',
	'tarik2113@hotmail.com',
	'meherbouazza@gmail.com',
	'm.essafir@gmail.com',
	'alphajiddou@gmail.com',
	'k.arbaoui@hotmail.fr',
	'danielecursoli@gmail.com',
	'lassananiakate1@yahoo.fr',
	'caruanajodo@gmail.com',
	'marchettiphilippe@sfr.fr',
	'thierry.poirier@me.com',
	'laubag@free.fr',
	'christophe.franchini@orange.fr',
	'stephane.cattier@ingka.ikea.com',
	'bakim2@hotmail.fr',
	'tissegouinepatricia@gmail.com',
	'h.seghdau.unsa@gmail.com',
	'pider33@hotmail.fr',
	'michel.braquet@unsa.org',
	'hammoutiabdelali@yahoo.fr',
	'philippe.wibaut@unsaapi.com',
	'cse-lagoa@maisons.dumonde.com',
	'hassan.rammane@sfr.fr',
	'ade.nacknouck@gmail.com',
	'bouchemal.claudine@gmail.com', // a confimer
];

export default emailArray;
/*
   
    //////////////////// emails
   	'lakram@hotmail.fr', 
	'alexandre.bonilla@yahoo.fr', 
	'assalbrigitte92@gmail.com', 
	'migan.unsa@yahoo.fr', 
	'unsa.proprete.nettoyage@gmail.com',
	'unsa.nettoyage@unsa.org', 
	'hfati@hotmail.fr', 
	'franck.martinez3@wanadoo.fr', 
	'freddy.hardet@orange.fr', 
	'sidibekalilou@yahoo.fr', 
	'unsa.hcr@unsa.org', 
	'khadijachlait@hotmail.com', 
	'lagomuche@orange.fr', 
	'mouloud.nadia@neuf.fr', 
	'unsa.adecco@gmail.com', 
	'unsa.manpower@gmail.com', 
	'fourseb13@gmail.com', 
	'pelmarthierry1@gmail.com', 
	'unsacompass@gmail.com',
	'lecomtejeanmichel1970@gmail.com',
	'bertrand.audoual@gmail.com',
	'frpoignant@orange.fr', 
	'kristoy@msn.com', 
	'wiartfreddy62@gmail.com', 
	'catherineacho@live.fr', 
	'cse-lagoa@maisons.dumonde.com', 
	'fatiha.mokhtari@yahoo.fr',
	'ade.nacknouck@gmail.com', 
	'vanessaarveyres@gmail.com',
	'tarik2113@hotmail.com', 
	'meherbouazza@gmail.com', 
	'm.essafir@gmail.com', 
	'alphajiddou@gmail.com', 
	'k.arbaoui@hotmail.fr', 
	'danielecursoli@gmail.com', 
	'lassananiakate1@yahoo.fr', 
	'caruanajodo@gmail.com', 
	'marchettiphilippe@sfr.fr', 
	'thierry.poirier@me.com', 
	'laubag@free.fr', 
	'christophe.franchini@orange.fr', 
	'stephane.cattier@ingka.ikea.com',
	'bakim2@hotmail.fr', 
	'tissegouinepatricia@gmail.com', 
	'h.seghdau.unsa@gmail.com', 
	'pider33@hotmail.fr',
	'michel.braquet@unsa.org', 
	'hammoutiabdelali@yahoo.fr',
	'philippe.wibaut@unsaapi.com', 
	'cse-lagoa@maisons.dumonde.com', 
	'hassan.rammane@sfr.fr', 
	'ade.nacknouck@gmail.com',
	'bouchemal.claudine@gmail.com', // a confimer
    */
