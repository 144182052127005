import React from 'react';
import banner from '../ban.jpg';

function Header() {
	return (
		<>
			<img className='w-full' src={banner} alt='' />
		</>
	);
}

export default Header;
